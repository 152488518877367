// Ref: 
// https://router.vuejs.org/guide/advanced/scroll-behavior.html
// https://github.com/vuejs/vue-router/blob/dev/examples/scroll-behavior/app.js

// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 }
  }
}
export default scrollBehavior