import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    razao_social: "NUVEM LABORATÓRIO DIGITAL LTDA ME",
    address: "Av. João de Barros, nº 1812, sala 210, Espinheiro, Recife-PE, CEP 52.021-180",
    cnpj: "14.766.041/0001-06",
    sociedade: "sociedade limitada",
    // razao_social: "MY BABY BOOK DESENVOLVIMENTO E LICENCIAMENTO LTDA",
    // address: "Av. Conselheiro Rosa e Silva, nº 1460, sala 1310, Caixa Postal 249, Aflitos, Recife-PE, CEP 52.050-245",
    // cnpj: "00.000.000/0000-00",
    // sociedade: "sociedade empresária",
  },
  mutations: {},
  actions: {},
  modules: {}
})
