const en = {
  is: "is a mobile application for Android and iOS smartphones that allows you to create a baby memory book. Capture and preserve the most significant moments of your child's life.",
  downloadNow: 'Download now from the stores.',
  appbar: {
    home: 'Home',
    about: 'About',
    contact: 'Contact',
  },
  rights: "All rights reserved.",
  privacy: "Privacy Policy",
  terms: "Terms of Use",
  aboutUs: "About Us",
  isAn: "My Baby Book is an Android and iOS smartphone application that transforms the traditional baby memory book into a high-quality digital experience.",
  ourStory: "Our Story",
  theConcept: "The concept of My Baby Book was born out of the often encountered difficulty of filling out traditional baby memory books. Unfortunately, it's common nowadays to come across incomplete entries in these books, as our busy lives can make it challenging to dedicate time to document each and every moment.",
  itWas: "It was precisely to address this issue that the idea of a baby memory book evolved into the innovative digital platform that My Baby Book is today.",
  ourMission: "Our Mission",
  provide: "Provide streamlined access to the precious memories of the baby's journey.",
  make: "Make the process of filling out information simple and intuitive.",
  offer: "Offer a variety of themes to cater to different family styles.",
  simplify: "Simplify the sharing of these memories, enabling others to follow the growth and development of this special child.",
  contactUs: "Contact Us",
  contactDescription: "If you have any suggestions, questions, or encounter any issues while using My Baby Book, please don't hesitate to reach out to us via email",
}
export default en;