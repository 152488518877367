const ptBR = {
  is: 'é uma aplicação móvel para smartphones Android e iOS que possibilita a criação de um livro de memórias do seu bebê. Capture e preserve os momentos mais significativos da vida do seu filho.',
  downloadNow: 'Baixe agora nas lojas.',
  appbar: {
    home: 'Início',
    about: 'Sobre',
    contact: 'Contato',
  },
  rights: "Todos os direitos reservados.",
  privacy: "Política de Privacidade",
  terms: "Termos de Uso",
  aboutUs: "Sobre Nós",
  isAn: "O My Baby Book é um aplicativo para smartphones Android e iOS que transforma o tradicional livro de recordações de bebê em uma experiência digital de alta qualidade.",
  ourStory: "Nossa História",
  theConcept: "O conceito do My Baby Book nasceu da dificuldade frequentemente encontrada ao preencher os tradicionais livros de recordações para bebês. Infelizmente, é comum nos dias de hoje encontrarmos esses livros incompletos, pois a vida movimentada muitas vezes torna desafiador dedicar tempo para documentar cada momento.",
  itWas: "Foi justamente para enfrentar esse problema que o conceito de um livro de recordações do bebê evoluiu para a plataforma digital inovadora que o My Baby Book é hoje.",
  ourMission: "Nossa Missão",
  provide: "Proporcionar um acesso simplificado às memórias preciosas da jornada do bebê.",
  make: "Facilitar o preenchimento das informações de maneira simples e intuitiva.",
  offer: "Oferecer uma variedade de temas para atender a diversos estilos familiares.",
  simplify: "Simplificar o compartilhamento dessas memórias, permitindo que outras pessoas acompanhem o crescimento e desenvolvimento dessa criança especial.",
  contactUs: "Entre em contato conosco",
  contactDescription: "Se você tiver alguma sugestão, pergunta ou encontrar qualquer problema ao usar o My Baby Book, por favor, não hesite em entrar em contato conosco através do email:",
}
export default ptBR;