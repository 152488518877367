import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import en from './en.js';
import ptBR from './pt-BR.js';

const userLanguage = navigator.language;

// Ready translated locale messages
const messages = {
  'en': en,
  'pt-BR': ptBR,
}

// Verificar se o idioma do usuário é suportado, caso contrário, definir inglês como padrão
let locale = userLanguage in messages ? userLanguage : 'en';

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale,
  messages,
})

export default i18n;
