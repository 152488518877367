<template>
  <div id="app">
    <div id="nav">
      <div class="container">
        <div class="logo">
          <router-link to="/">
            <span class="my">my</span>
            <span class="baby">baby</span>
            <span class="book">book</span>
          </router-link>
        </div>
        <div class="nav-routes">
          <router-link to="/">{{ $t("appbar.home") }}</router-link>
          <router-link to="/about">{{ $t("appbar.about") }}</router-link>
          <router-link to="/contact">{{ $t("appbar.contact") }}</router-link>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="content-view">
        <router-view />
      </div>
    </div>
    <div id="footer">
      <div class="container">
        <div class="copy">
          <img alt="MyBabyBook logo" src="@/assets/images/icon.png" />
          © {{ new Date().getFullYear() }} My Baby Book. {{ $t("rights") }}
        </div>
        <div class="links">
          <router-link :to="to('/privacy')">{{ $t("privacy") }}</router-link>
          <router-link :to="to('/terms')">{{ $t("terms") }}</router-link>
          <select v-model="$i18n.locale">
            <option value="en">en</option>
            <option value="pt-BR">pt-BR</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  methods: {
    to(path) {
      var lang = this.$i18n.locale;
      if (lang != "en" && lang != "pt-BR") {
        return path + "/en";
      }
      return path + "/" + lang;
    },
  },
};
</script>
<style>
@import "./assets/styles/main.css";
</style>
