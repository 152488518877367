import Vue from 'vue'
import VueRouter from 'vue-router'
import scrollBehavior from './scrollBehavior.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  
  {
    path: '/privacy',
    name: 'Privacy',
    redirect: '/privacy/en',
    component: () => import('../views/routerView.vue'),
    children: [
      {
        path: 'en',
        name: 'PrivacyEN',
        component: () => import('../views/privacy/Privacy.vue')
      },
      {
        path: 'pt-BR',
        name: 'PrivacyPTBR',
        component: () => import('../views/privacy/PrivacyPTBR.vue')
      }
    ]
  },
  {
    path: '/terms',
    name: 'Terms',
    redirect: '/terms/en',
    component: () => import('../views/routerView.vue'),
    children: [
      {
        path: 'en',
        name: 'TermsEN',
        component: () => import('../views/terms/Terms.vue')
      },
      {
        path: 'pt-BR',
        name: 'TermsPTBR',
        component: () => import('../views/terms/TermsPTBR.vue')
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior,
  base: process.env.BASE_URL,
  routes
})

export default router;
